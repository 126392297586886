import './App.css';
import { db } from './firebase';
import { collection, addDoc, setDoc, doc, updateDoc, getDocs } from "firebase/firestore";
import React, { useState, useEffect } from 'react';
import { parse, format } from 'date-fns';


function App() {
  const gifs = [  'https://media.giphy.com/media/YSGT296mY6Ia8ZshrM/giphy.gif',  'https://res.cloudinary.com/dlb2fx0pa/image/upload/v1678152738/ezgif-2-d7bb94a78f_rviar1.gif',  'https://media.giphy.com/media/26u6bgaSBOLgFO5he/giphy.gif',  'https://media.giphy.com/media/eOkhXuornySWs/giphy.gif',  'https://media.giphy.com/media/2wh8dIGFpOX6NpAkle/giphy.gif',  'https://media.giphy.com/media/SdbDGOEkSDx6woN3Oe/giphy.gif',  'https://res.cloudinary.com/dlb2fx0pa/image/upload/v1678153047/hungry-dog-hungry_dyb4cw.gif',  'https://res.cloudinary.com/dlb2fx0pa/image/upload/v1678153063/get-it-come-and-get-it_fcdnul.gif',  'https://res.cloudinary.com/dlb2fx0pa/image/upload/v1678153084/doge-dog-bowl_lwhbm4.gif'];

  const currentDate = new Date().toLocaleDateString();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedDate, setSelectedDate] = useState(new Date());

  function handlePrevDay() {
    setSelectedDate(prevDate => new Date(prevDate.getTime() - 24 * 60 * 60 * 1000));
  }
  
  function handleNextDay() {
    setSelectedDate(prevDate => new Date(prevDate.getTime() + 24 * 60 * 60 * 1000));
  }

  async function readfb() {
    const colRef = collection(db, "events");
    const docsSnap = await getDocs(colRef);
    console.log(docsSnap)
    const currentDate = new Date();
    const temp = []
    docsSnap.forEach(doc => {
      const dateString = doc.data().date;
      const date = new Date(dateString);
      const formattedDate = date.toLocaleDateString();
      const today = new Date();
      //Change back
      // const formattedToday = today.toLocaleDateString()
      const formattedToday = selectedDate.toLocaleDateString();
      console.log(formattedDate,selectedDate.toLocaleDateString())
        const event = {
          date: formattedDate,
          deffood: doc.data().deffood,
          description: doc.data().description,
          guessfood: doc.data().guessfood,
          time: doc.data().time,
          title: doc.data().title,
          url: doc.data().url,
          meal: doc.data().meal
        }
        temp.push(event)
      
    })
    console.log(temp)
    setData(temp)
    setLoading(false)
    // return final;
  }

  useEffect(() => {
    readfb();
    console.log(data)
  }, []);


  const meals = ['breakfast', 'lunch', 'dinner'];

  return (
    <div className="menu">
      <div>
        <button onMouseDown={handlePrevDay}>{'<'}</button>
          <span>{format(selectedDate, 'MMMM d, yyyy')}</span>
        <button onMouseDown={handleNextDay}>{'>'}</button>
      </div>
      <h1>Stanfood</h1>
      <p style = {{marginTop:'-2vh'}}>Feeding students since 2023</p>
      
      <div>
      {loading ? <></>
      : <>
      {data.filter((item) => item.date === selectedDate.toLocaleDateString()).length > 0 ? (
      meals.map((meal) => (
        <div key={meal}>
          {data.filter((item) => item.meal === meal && item.date === selectedDate.toLocaleDateString()).length > 0 && (
            <ul>
              <li>
                <h2>{meal.charAt(0).toUpperCase() + meal.slice(1)}</h2>
                {data.map((item) => (
                  (item.meal === meal&& item.date==selectedDate.toLocaleDateString()) && (
                    <div key={item.id}>
                      <h3 style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <span><a href={item.url} style={{ textDecoration: 'none', color: 'black' }}>{item.title}</a></span>
                        {/* <span>{item.guessfood === 0 ? '100% Chance' : `${item.guessfood * 100}% Chance`}</span> */}
                      </h3>
                      <p style={{ marginTop: '-2vh' }}><div>{item.time.slice(0, -7)}</div></p>
                      <p style={{ marginBottom: '1vh' }}>{item.description.trim().replace(/\n$/, '')}</p>
                    </div>
                  )
                ))}
              </li>
            </ul>
          )}
        </div>
      ))
    ) : (
      <div className="gif-container">

      <img src={gifs[Math.floor(Math.random() * gifs.length)]} alt="Sad face GIF" />
      </div>
    )}</>
    }
    </div>

    </div>
  );
}

export default App;
